import { convertUtcToMMDD } from "./utils";

const DEFAULT_VALUE = "N/A";

class PendingRegisterModel {
    constructor(data) {
        this.ScacCode = data?.ScacCode || DEFAULT_VALUE;
        this.Location = data?.Location || DEFAULT_VALUE;
        this.Truck = data?.Truck || DEFAULT_VALUE;
        this.Name = data?.Name || DEFAULT_VALUE;
        this.Container = data?.Containner || DEFAULT_VALUE;
        this.Chassis = data?.Chassis || DEFAULT_VALUE;
        this.DateRequestFlip = data?.DateRequestFlip
            ? convertUtcToMMDD(data.DateRequestFlip)
            : DEFAULT_VALUE;
        this.TimeInYard = data?.TimeInYard || DEFAULT_VALUE;
        this.Receipt = data?.Receipt || DEFAULT_VALUE;
        this.YardTransactionId = data?.YardTransactionId || 0;
        this.FlipPendingId = data?.FlipPendingId || 0;
        this.NewScacCode = data?.NewScacCode || DEFAULT_VALUE;
    }
}

export default PendingRegisterModel;
