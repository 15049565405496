import { StoreProvider } from "./store/storeContext";
import FieldRow from "./components/FieldRow/FieldRow";
import TableFlips from "./components/Table/Table";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

export default function Root(props) {
  return <>
    <section style={{ paddingRight: '3%', paddingLeft: '3%', paddingTop: '3%', backgroundColor: '#f0f0f0' }}>
      <StoreProvider>
        <CssBaseline />
        <FieldRow />
        <TableFlips />
      </StoreProvider>
    </section>
  </>
}
