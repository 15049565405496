import * as React from 'react'
import { StoreContext } from '../../store/storeContext';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Button from '@mui/material/Button';
import { getPendingFlip } from '../../services/services';
import PendingRegisterModel from '../../models/models';

const useTableController = () => {

    //Constants
    const { containerFiltered, scacFiltered, tableSelected, locationSelected } = React.useContext(StoreContext);
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [title, setTitle] = React.useState('Pending Flip');
    const [columns, setColumns] = React.useState([]);
    const [table, setTable] = React.useState(true); //true = pending, false = history
    const [rows, setRows] = React.useState([]);
    const getRowId = (row) => row.position;




    //Events

    const handleButtonClick = (row) => {
        console.log(row)
        const data = { YardTransactionId: row.YardTransactionId, NewScacCode: row.NewScacCode, ScacCode: row.ScacCode, Container: row.Container, Chassis: row.Chassis}
        const evento = new CustomEvent('flipChassis', { detail: data });
        window.dispatchEvent(evento);
    }


    React.useEffect(() => {
        const handleChassisFlipped = () => {
            fetchData();
        };

        window.addEventListener('chassisFliped', handleChassisFlipped);

        return () => {
            window.removeEventListener('chassisFliped', handleChassisFlipped);
        };
    }, []);

    //Columns
    const columnsPending = [
        {
            field: 'position',
            headerName: '#',
            width: 150,
            headerAlign: 'center',
            align: "center"
        },
        {
            field: 'action',
            width: 200,
            headerName: 'Action',
            headerAlign: 'center',
            align: "center",
            renderCell: (params) => (
                <Button variant="contained" color="primary" startIcon={<SyncAltIcon />} onClick={() => handleButtonClick(params.row)}>
                    Flip Chassis
                </Button>
            ),
        },
        {
            field: 'ScacCode',
            headerName: 'SCAC',
            width: 100,
            headerAlign: 'center',
            align: "center"
        },
        {
            field: 'Location',
            headerName: 'Location',
            width: 200,
            headerAlign: 'center',
            align: "center"
        },
        {
            field: 'Truck',
            headerName: 'Truck',
            width: 100,
            headerAlign: 'center',
            align: "center"
        },
        // { field: 'Name', flex: 1, headerName: 'Name' },
        {
            field: 'Container',
            headerName: 'Cont/Trl Num',
            minWidth: 200,
            headerAlign: 'center',
            align: "center",
            flex: 1,
        },
        {
            field: 'Chassis',
            headerName: 'Chassis N',
            minWidth: 200,
            headerAlign: 'center',
            align: "center",
            flex: 1,
        },
        {
            field: 'DateRequestFlip',
            headerName: 'Date',
            minWidth: 200,
            headerAlign: 'center',
            align: "center",
            flex: 1,
        },
        {
            field: 'TimeInYard',
            headerName: 'Time in Yard',
            minWidth: 200,
            headerAlign: 'center',
            align: "center",
            flex: 1,
        },
        // { field: 'Receipt', flex: 1, headerName: 'Receipt N' },
    ];
    const columnsHistory = [
        { field: 'position', flex: 0.3, headerName: '#' },
        { field: 'scac', flex: 0.5, headerName: 'SCAC' },
        { field: 'location', flex: 1, headerName: 'Location' },
        { field: 'truck', flex: 0.5, headerName: 'Truck' },
        { field: 'license', flex: 0.5, headerName: 'License' },
        { field: 'name', flex: 1, headerName: 'Name' },
        { field: 'cont_trl_num', flex: 1, headerName: 'Cont/Trl Num' },
        { field: 'old_chassis', flex: 1, headerName: 'Old Chassis' },
        {
            field: 'new_chassis',
            flex: 1,
            headerName: 'New Chassis',
            renderCell: (params) => (
                <span style={{ color: '#3288FC' }}>{params.value}</span>
            ),
        },
        { field: 'date', flex: 1, headerName: 'Date' },
        { field: 'time_in_yard', flex: 1, headerName: 'Time in Yard' },
        { field: 'receipt_number', flex: 1, headerName: 'Receipt N' },
    ]
    //UseEffect


    // React.useEffect(() => {
    //     setFilteredRows(tableSelected ? rowsPending : rowsHistory);
    //     setTitle(tableSelected ? 'Pending Flip' : 'Flip History');
    //     setColumns(tableSelected ? columnsPending : columnsHistory);

    // }, [tableSelected]);

    React.useEffect(() => {
        let filteredRows = rows;
        if (locationSelected === 'All') {
        } else {
            filteredRows = filteredRows.filter(
                (row) => row.Location.toLowerCase().includes(locationSelected.toLowerCase())
            );
        }
        if (containerFiltered) {
            filteredRows = filteredRows.filter(
                (row) => row.Container.toLowerCase().includes(containerFiltered.toLowerCase())
            );
        }
        if (scacFiltered) {
            filteredRows = filteredRows.filter(
                (row) => row.ScacCode.toLowerCase().includes(scacFiltered.toLowerCase())
            );
        }
        setFilteredRows(filteredRows);
    }, [locationSelected, containerFiltered, scacFiltered]);


    // const data = [
    //     { "position": 1, "ScacCode": "ABC", "Location": "Location 1", "Truck": "Truck 1", "Container": "Container 1", "Chassis": "Chassis 1", "DateRequestFlip": "2024-02-27", "TimeInYard": "2 hours ago" },
    //     { "position": 2, "ScacCode": "DEF", "Location": "Location 2", "Truck": "Truck 2", "Container": "Container 2", "Chassis": "Chassis 2", "DateRequestFlip": "2024-02-27", "TimeInYard": "3 hours ago" },
    //     { "position": 3, "ScacCode": "GHI", "Location": "Location 3", "Truck": "Truck 3", "Container": "Container 3", "Chassis": "Chassis 3", "DateRequestFlip": "2024-02-27", "TimeInYard": "4 hours ago" },
    //     { "position": 4, "ScacCode": "JKL", "Location": "Location 4", "Truck": "Truck 4", "Container": "Container 4", "Chassis": "Chassis 4", "DateRequestFlip": "2024-02-27", "TimeInYard": "5 hours ago" },
    //     { "position": 5, "ScacCode": "MNO", "Location": "Location 5", "Truck": "Truck 5", "Container": "Container 5", "Chassis": "Chassis 5", "DateRequestFlip": "2024-02-27", "TimeInYard": "6 hours ago" }
    // ]

    React.useEffect(() => {
        fetchData();
        setColumns(columnsPending);
    }, [])

    const fetchData = async () => {
        setFilteredRows([]);
        setRows([]);
        setTable(true);

        getPendingFlip().then(response => {

            const registers = response.data.FlipPendingResponse;
            if (registers === null) {
                setFilteredRows([]);
                setRows([]);
                setTable(false);
                return;
            }
            else {
                const pendingRegisters = registers.map((register, index) => ({
                    ...new PendingRegisterModel(register),
                    position: index + 1,
                }));
                setRows(pendingRegisters);
                setFilteredRows(pendingRegisters);
                setTable(false);
            }

        }).catch(error => {
            console.error("Error al obtener datos:", error);
        });

    }
    return {
        columns,
        rows: filteredRows,
        getRowId,
        title,
        table,
    }
}
export default useTableController;