import axios from 'axios';

export const getLocations = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_STORAGEACC_URL);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las ubicaciones:', error);
        throw error; 
    }
};

export const getPendingFlip = async () => {
    const headers = {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
        "Content-Type": "application/json",
    };
    const config = {
        headers: headers,
        method: "get",
        url: process.env.REACT_APP_GET_PENDING_FLIPS,
    };
    const response = axios(config);
    return response;


}
