export const convertUtcToMMDD = (utcDate) => {
    const date = new Date(utcDate);
  
    // Obtener componentes de la fecha en formato UTC
    const month = date.getUTCMonth() + 1; // months from 0 to 11
    const day = date.getUTCDate();
  
    // Formatear el mes y el día con dos dígitos cada uno
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
  
    return `${formattedMonth}/${formattedDay}`;
  };
  