import * as React from 'react';
import { StoreContext } from '../../store/storeContext';
import { getLocations } from '../../services/services';
const useFieldRowController = () => {
    const { setContainerFiltered, setScacFiltered, tableSelected, setTableSelected, locationSelected, setLocationSelected } = React.useContext(StoreContext);

    //Consants
    const [locations, setLocations] = React.useState([]);

    const fetchLocations = async () => {
        const locations = await getLocations();
        setLocations(locations);
    }

    //Functions
    const handleClearFilters = () => {
        const scac = document.getElementById('scacTextfield');
        const cont = document.getElementById('contTextField');
        scac.value = '';
        cont.value = '';
        setContainerFiltered('');
        setScacFiltered('');
        setLocationSelected('All');
    }

    const handleTableSelected = (state) => {
        handleClearFilters();
        setTimeout(() => {
            setTableSelected(state);
        }, 100);
    }

    const handleSetLocationSelected = (e) => {
        setLocationSelected(e.target.value);
    }
    //UseEffect
    React.useEffect(() => {
        fetchLocations();
    }, []);
    return {
        setContainerFiltered,
        setScacFiltered,
        handleClearFilters,
        handleTableSelected,
        tableSelected,
        locations,
        locationSelected,
        handleSetLocationSelected
    }
}
export default useFieldRowController;